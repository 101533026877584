import React from "react"
import { useTranslation } from "react-i18next"
import { Routes, Route, Navigate } from "react-router-dom"

const Login = React.lazy(() => import("pages/Login"))

const AuthRoutes = () => {
  const { t } = useTranslation()

  return (
    <React.Suspense fallback={<span>{t("loading")}</span>}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </React.Suspense>
  )
}

export default AuthRoutes
