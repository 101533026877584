import common from "./common.json"
import errors from "./errors.json"
import dashboard from "./dashboard.json"
import customer from "./customer.json"
import booking from "./booking.json"
import order from "./order.json"
import product from "./product.json"
import blog from "./blog.json"
import history from "./treatment-history.json"
import card from "./card.json"
import setting from "./setting.json"
import content from "./content.json"
import staff from "./staff.json"
import checkin from "./checkin.json"
import transaction from "./transaction.json"
import treatment from "./treatment.json"

const en = {
  translation: {
    ...common,
    ...errors,
    ...dashboard,
    ...customer,
    ...booking,
    ...order,
    ...product,
    ...blog,
    ...history,
    ...card,
    ...setting,
    ...content,
    ...staff,
    ...checkin,
    ...transaction,
    ...treatment,
  },
}

export default en
