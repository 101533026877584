import { createSlice } from "@reduxjs/toolkit"

const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    selectedTransaction: null,
  },
  reducers: {
    setSelectedTransaction: (state, action) => {
      state.selectedTransaction = action.payload
    },
  },
})

export const {
  reducer: transactionReducer,
  actions: { setSelectedTransaction },
} = transactionSlice
