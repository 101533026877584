import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer } from "react-toastify"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import localeData from "dayjs/plugin/localeData"

import AuthLayout from "layouts/AuthLayout"
import MainLayout from "layouts/MainLayout"
import AuthRoutes from "router/AuthRoutes"
import MainRoutes from "router/MainRoutes"
import { getListRoles } from "services/api/roles"
import "react-toastify/dist/ReactToastify.css"
import { setStaffRoles } from "slice/userSlice"
import { USER_ROLE } from "constants/Authentication"
dayjs.extend(localeData)

function App() {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.user.currentUser)

  useEffect(() => {
    ;(async () => {
      const res = await getListRoles()
      if (res?.data) {
        dispatch(
          setStaffRoles(
            res?.data?.roles?.filter(
              (role) => ![USER_ROLE.AUTHENTICATED, USER_ROLE.PUBLIC]?.includes(role.type)
            )
          )
        )
      }
    })()
  }, [dispatch])

  useEffect(() => {
    if (i18n.language === "vi") {
      import(`dayjs/locale/vi`).then(() => dayjs.locale("vi"))
    } else {
      import(`dayjs/locale/en-gb`).then(() => dayjs.locale("en-gb"))
    }
  }, [i18n.language])

  if (!currentUser) {
    return (
      <AuthLayout>
        <AuthRoutes />
        <ToastContainer />
      </AuthLayout>
    )
  }

  return (
    <MainLayout key={i18n.language}>
      <MainRoutes />
      <ToastContainer />
    </MainLayout>
  )
}

export default App
