export const SIDEBAR_ITEMS = [
  {
    id: "dashboard",
    name: "dashboard.title",
    icon: "sidebar/dashboard",
    url: "/",
  },
  {
    id: "customers",
    name: "customers",
    icon: "sidebar/customer",
    url: "/customers",
    apiKey: "plugin::users-permissions",
    controllerKey: "user",
  },
  {
    id: "bookings",
    name: "bookings",
    icon: "sidebar/bookings",
    url: "/bookings",
    apiKey: "api::booking",
    controllerKey: "booking",
  },
  {
    id: "orders",
    name: "orders",
    icon: "sidebar/orders",
    url: "/orders",
    apiKey: "api::order",
    controllerKey: "order",
  },
  {
    id: "products",
    name: "products",
    icon: "sidebar/products",
    url: "/products",
    subItems: [{ name: "product_settings", url: "/product-settings" }],
    apiKey: "api::product",
    controllerKey: "product",
  },
  {
    id: "treatments",
    name: "treatments",
    icon: "sidebar/treatments",
    url: "/treatments",
    subItems: [{ name: "treatment_category", url: "/treatment-category" }],
    apiKey: "api::treatment",
    controllerKey: "treatment",
  },
  {
    id: "treatment-history",
    name: "treatment_history",
    icon: "sidebar/treatment-history",
    url: "/treatment-history",
    apiKey: "api::treatment-history",
    controllerKey: "treatment-history",
  },
  {
    id: "blogs",
    name: "Blogs",
    icon: "sidebar/blogs",
    url: "/blogs",
    subItems: [{ name: "blog_categories", url: "/blog-categories" }],
    apiKey: "api::blog",
    controllerKey: "blog",
  },
  {
    id: "website-content",
    name: "website_content",
    icon: "sidebar/website-content",
    url: "/website-content",
    subItems: [
      { name: "banner", url: "/banner" },
      { name: "about", url: "/about" },
      { name: "privacy", url: "/privacy" },
      { name: "terms", url: "/terms" },
      { name: "FAQs", url: "/faqs" },
    ],
    apiKey: "api::privacy",
    controllerKey: "privacy",
  },
  {
    id: "staffs",
    name: "staffs",
    icon: "sidebar/staffs",
    url: "/staffs",
    apiKey: "plugin::users-permissions",
    controllerKey: "permissions",
  },
  {
    id: "check-in",
    name: "check_in",
    icon: "sidebar/check-in",
    url: "/check-in",
    apiKey: "api::check-in",
    controllerKey: "check-in",
    subItems: [{ name: "check_in_history", url: "/check-in/history" }],
  },
  {
    id: "membership-card",
    name: "membership_card",
    icon: "sidebar/membership-card",
    url: "/membership-card",
    apiKey: "api::membership-card",
    controllerKey: "membership-card",
  },
  {
    id: "service-card",
    name: "service_card",
    icon: "sidebar/membership-card",
    url: "/service-card",
    apiKey: "api::membership-card",
    controllerKey: "membership-card",
    subItems: [{ name: "card.card_template", url: "/card-templates" }],
  },
  {
    id: "media-library",
    name: "media_library",
    icon: "sidebar/media-library",
    url: "/media-library",
    apiKey: "plugin::upload",
    controllerKey: "content-api",
  },
  {
    id: "transaction",
    name: "transactions",
    icon: "sidebar/coin",
    url: "/transactions",
    apiKey: "api::transaction",
    controllerKey: "transaction",
  },
  {
    id: "report",
    name: "report",
    icon: "sidebar/report",
    url: "/reports",
    apiKey: "api::term",
    controllerKey: "term",
  },
  {
    id: "settings",
    name: "settings",
    icon: "sidebar/settings",
    url: "/settings",
    apiKey: "api::setting",
    controllerKey: "setting",
  },
]
